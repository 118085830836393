.item-container{
    height: 150px;  
    border-radius: 4px;
    width: 380px;
    margin: 10px; 
    display: flex;
    flex-direction: row;
    border: 2px solid #060914;
    box-shadow:0 0 10px 3px #060914;
}

.thumbnail-image img{
    border-radius: 4px 0px 0px 4px;
    width: 120px;
    height: 100%;
    object-fit: cover;
}

.item-content{
    width: 100%;
}

.item-header{
    border-radius: 0px 4px 4px 0px;
    background-color: #011124 ; /*third color*/
    color: rgb(233, 232, 232); 
    height: 50%;
    max-height: 50%;
    display: flex;
    align-items: center;
    padding: 10px;
    text-align: left;
    
}
.item-header h3{
    font-size: calc(100%);
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    margin: 0px; 
}
.item-header p{
    font-size: .9rem;
}
.item-body{
    height: 100%;
}
.item-body p{
    color: rgb(211, 208, 208);
    font-size: .8rem;
    padding: 8px 0px 0px 8px;
    text-align: left;
    /* white-space: nowrap; */
    overflow: hidden;
}



/*
    ITEM VIDEO 
*/

.item-video-container{
    height: 140px ;
    width: 250px;
    margin: 10px;
    
}

.item-video-container img{
    border-radius: 4px;
    box-shadow: inset 0 0 10px #000000;
    object-fit: cover;
    height: 100%;
    width: 100%;
}


/* tablets */
@media (max-width: 768px){
    .item-container{
        width: 300px;
    }
    .item-header h3{
        font-size: calc(100% - .01rem);

        margin: 15px;
    }
}

/* Laptop S */
@media (max-width: 1024px){
    .item-container{
        width: 300px;
    }
    .item-header{
        padding: 2px 5px;
    }
    .item-header h3{
        margin: 5px; 
        font-size: calc(100% - .1rem);
    }
    .item-body p{
        font-size: .7rem;

    }
}


/* phones */
@media (max-width: 425px){
    .item-container{
        height: 80px;
        width: 380px;
    }
    .thumbnail-image img{
        border-radius: 4px 0px 0px 4px;
        width: 80px;
    }
    .item-header{
        height: 100%;

        max-height: 100%;
    }
    .item-header h3{
        font-size: 0.9rem;
        margin: 0px 10px; 
    }
    .item-body{
        display: none;
    }
}