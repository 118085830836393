#root{
  height: 100%;
  overflow-y: scroll;
}

.App {
  text-align: center;
  min-height: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-items: center;
}



a{
  text-decoration: none;
  color: white;
  display: inline-block;
}

h5{
  margin: 0px;
}

.navbar-container{
  height: 4rem;
  box-shadow: 0px 0px 10px 0px #0f0d1d;
}

.navbar-container .responsive-icon{
  width: 30px;
  height: 30px;
  border: 1px solid white;
}

.navbar-toggler{
  color: white;
}

.content-videos, .content-blogs{
  padding: 10px;
  opacity: 100;
}
.content-blogs{
  display: flex;
  max-width: 100vw;
  min-width: 80vw;
  justify-content: center;
  margin: 0px auto;
  flex: 0 0 33.333333%;
  flex-wrap: wrap;
  transition: ease-in 200ms all;
}

.content-type-container{
  display: flex;
  margin: 0px auto; 
  flex-direction: row;
  justify-content: flex-start;
}

.hidden-content{
  opacity: 0%;
}
.content-type-container button{
  background: none;
  font-size: 1.1rem;
  border: none;
  margin: 5px 10px; 
  outline: none;
  color: rgb(53, 107, 243); 
  cursor: pointer;
  transition: all ease 200ms;
}
.content-type-container button.selected{
  color: white; 
  cursor: auto;
  font-size: 1.2rem;
  font-weight: 600;
}

.content-type-container button:focus{
  outline: none;
}

/*phones*/
@media (max-width: 992px){
  .content-blogs{
    width: 87%;
  }
  .navbar-collapse.show .navbar-nav{
    background: linear-gradient(0deg, #030921 0%, #020615 100%);
    box-shadow: 0px 10px 13px -6px #06154e;
    border-radius: 2px;
  }

}

/*tablets*/
@media (max-width: 768px){
  .content-blogs{
    justify-items: center;
  }
}

/*Laptop S*/
@media (max-width: 1024px){

}
/*Laptop L*/
@media (min-width: 1440px){
  .content-blogs{
    /* width: 85%; */
  }
}